import Stack from '@mui/material/Stack';
import React, {FC} from 'react';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import type {LineItem} from '@local/backend/@types/updated-api-types/items/LineItem';
import {MoneyUtils} from '@handsin/money';
import {useTranslation} from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import {useSplitByItemHelper} from '@local/frontend/hooks/queries/frontend/SBI/useSplitByItemHelper';
import useCachedGroupPayment from '@local/frontend/hooks/useCachedGroupPayment';
import {useTheme} from '@mui/material';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import useMerchant from '@local/frontend/hooks/data/merchants';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {useCustomer} from '@local/frontend/hooks/queries/customers';
import LineItemImage from '../../../LineItemImage';
import SbiMergedFlowQuantityButtons from './SbiMergedFlowQuantityButtons';

interface SbiMergedFlowLineItemProps {
  lineItem: LineItem;
  selectedCustomerId: string | undefined;
  viewOnly?: boolean;
}
const SbiMergedFlowLineItem: FC<SbiMergedFlowLineItemProps> = ({
  lineItem,
  selectedCustomerId,
  viewOnly,
}) => {
  const theme = useTheme();
  const {t} = useTranslation(['line-item', 'sbi-quantity-buttons']);
  const groupPayment = useCachedGroupPayment();
  const sbiHelper = useSplitByItemHelper(groupPayment);
  const merchant = useMerchant();

  const {openModal} = useCustomModals();
  const {data: currentCustomer} = useCustomer(selectedCustomerId);

  const handleMoreInfoClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    openModal(ModalName.LINE_ITEM_INFO, {lineItem, merchant});
  };

  const handleSelect = (customerId: string) => {
    sbiHelper.allocate(customerId, lineItem.item.id, 1);
  };

  const handleItemSelect = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedCustomerId) {
      handleSelect(selectedCustomerId);
    }
  };

  const currentAllocatedItem = selectedCustomerId
    ? sbiHelper.itemAlloc?.[selectedCustomerId]?.find(
        (item) => item.itemId === lineItem.item.id
      )
    : undefined;

  const remainingQuantityAvailable = sbiHelper.getRemainingQuantityAvailable(
    lineItem.item.id
  );

  const isAllocated = currentAllocatedItem
    ? currentAllocatedItem.quantity >= 1
    : false;

  return (
    <Stack direction="column" width="100%" spacing={1}>
      <Stack
        direction="row"
        flex="auto"
        alignItems="center"
        spacing={2}
        minWidth={0}
      >
        <LineItemImage
          sx={{
            width: {xs: 32, sm: 56},
            height: '100%',
            aspectRatio: '1/1',
            borderRadius: {xs: '4px'},
          }}
          variant="rounded"
          lineItem={lineItem}
        />
        <Stack direction="column" width="100%" minWidth={0}>
          <Typography variant="subtitle1" noWrap width="100%">
            {lineItem.item.name}
          </Typography>
          <Typography variant="body2" noWrap width="100%">
            {t('lineItemQuantityAndPrice', {
              ns: 'line-item',
              quantity: lineItem.quantity,
              money: MoneyUtils.formatMoney(lineItem.item.amountMoney),
            })}
          </Typography>
          {(!!lineItem.item.description ||
            !_.isEmpty(lineItem.item.attributes)) && (
            <Box>
              <Typography
                sx={{
                  width: ' fit-content',
                  display: 'inline-flex',
                  alignItems: 'center',
                  fontWeight: 600,
                  textDecorationStyle: 'dashed',
                  textDecorationLine: 'underline',
                  textDecorationColor: theme.palette.primary.dark,
                  textDecorationThickness: 1,
                  fontSize: 14,
                  color: theme.palette.primary.dark,
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                noWrap
                onClick={handleMoreInfoClick}
              >
                <InfoIcon sx={{fontSize: 14, mr: 0.5}} />
                {t('moreInfo', {ns: 'line-item'})}
              </Typography>
            </Box>
          )}
        </Stack>
      </Stack>

      {!viewOnly && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          flex="auto"
          spacing={2}
        >
          {selectedCustomerId && currentAllocatedItem && (
            <SbiMergedFlowQuantityButtons
              remainingQuantityAvaliable={remainingQuantityAvailable}
              currentAllocatedItem={currentAllocatedItem}
              selectedCustomerId={selectedCustomerId}
            />
          )}

          {!(selectedCustomerId && currentAllocatedItem) && (
            <FormControlLabel
              sx={{
                display: 'flex',
                flexGrow: 0,
                minWidth: 0,
              }}
              control={
                <Checkbox
                  onClick={handleItemSelect}
                  checked={isAllocated}
                  sx={{p: 0, ml: 1}}
                  icon={
                    <CheckBoxOutlineBlankIcon sx={{color: 'primary.dark'}} />
                  }
                  disableRipple
                />
              }
              labelPlacement="start"
              label={
                <Typography noWrap>
                  {t('assignTo', {ns: 'line-item'})}{' '}
                  <Box
                    sx={{
                      color: 'primary.dark',
                      fontWeight: 600,
                      display: 'inline',
                    }}
                  >
                    {currentCustomer?.firstName}
                  </Box>
                </Typography>
              }
            />
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default SbiMergedFlowLineItem;
